import request from '@/utils/request'
const service = process.env.VUE_APP_RMS_URL
// 获取用户信息
export function getInfo() {
    return request({
        url: service+ '/getInfo',
        method: 'get'
    })
}

// 查询部门列表
export function listDept(query) {
    return request({
        url: service+ '/system/dept/list',
        method: 'get',
        params: query
    })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
        url: service+ '/system/dict/data/type/' + dictType,
        method: 'get'
    })
}
// 审批操作
export function approval(data) {
    return request({
        url: service+ '/workflow/approval',
        method: 'post',
        data
    })
}

/**
 * 查询费用类型-下拉选框用
 * @param {*} data {parentId,isApprove}
 *  isApprove：1是 0否，当前获取列表的是否是审批人，是审批类型的话需要展示全部费用项，保证详情页面能回显
 */
export function selectCostTypeTreeData(data) {
    return request({
        url: service+ '/cost/type/selectCostTypeTreeData',
        method: 'post',
        data
    })
}

//post method= {post | put}
export function httpAction(url, parameter, method) {
    // let sign = signMd5Utils.getSign(url, parameter);
    //将签名和时间戳，添加在请求接口 Header
    // let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getDateTimeToString() };
    return request({
        url: service+ url,
        method: method,
        data: parameter,
        // headers: { isToken: false },
        timeout: 30000
    })
}

/** OCR识别发票信息 */
export function OCRGetData(fileId) {
    return request({
        url: service+ '/reimburse/out/OCR',
        method: 'post',
        data: { fileId }
    })
}

/** 查询外币转人民币的汇率 */
export function selectExchangeCurrency(from) {
    return request({
        // headers: { isToken: false },
        url: service+ '/upi/selectExchangeCurrency',
        method: 'post',
        data: { from }
    })
}

/** 获取所属费用类型 */
export function kindToCostType(data) {
    return request({
        url: service+ '/word/kindToCostType',
        method: 'post',
        data
    })
}

/** 安卓文件上传 */
export function uploadFileByPath(data) {
    return request({
        url: service+ '/oosfileUpload/uploadFileByPath',
        method: 'post',
        data,
        // headers: { isToken: false }
    })
}
