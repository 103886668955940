import {
	ImagePreview
} from "vant"
import { Base64 } from 'js-base64'
// 深度clone
export function deepClone(obj) {
	if (typeof obj != 'object') {
		return obj;
	}

	const targetObj = obj.constructor === Array ? [] : {}
	Object.keys(obj).forEach(keys => {
		if (obj[keys] && typeof obj[keys] === 'object') {
			targetObj[keys] = deepClone(obj[keys])
		} else {
			targetObj[keys] = obj[keys]
		}
	})
	return targetObj
}

/**
 * @description 格式化日期
 * @param time 时间
 * @param t 以什么字符拼接
 * @param y 1返回年月日时分秒 2返回年月日
 */
export const formatDate = (time, t = '-', y = 2) => {
	var date = new Date(time);

	var year = date.getFullYear(),
		month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1, //月份是从0开始的
		day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
		hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(),
		min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes(),
		sec = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	var newTime = ''
	if (y === 1) {
		newTime = `${year}${t}${month}${t}${day} ${hour}:${min}:${sec}`
	}
	if (y !== 1) {
		newTime = year + t +
			month + t +
			day
	}
	return newTime;
}

export function formatDate2(date, format) {
	date = new Date(date);

	var o = {
		'M+': date.getMonth() + 1, //month
		'd+': date.getDate(), //day
		'H+': date.getHours(), //hour
		'm+': date.getMinutes(), //minute
		's+': date.getSeconds(), //second
		'S': date.getMilliseconds() //millisecond
	};

	if (/(y+)/.test(format))
		format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

	for (var k in o)
		if (new RegExp('(' + k + ')').test(format))
			format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

	return format;
}


/**
 * 获取几天前的日期
 */

export function getBeforeDate(n, t, y) {
	// var n = n;
	var d = new Date();
	var year = d.getFullYear(),
		mon = d.getMonth() + 1,
		day = d.getDate();
	if (day <= n) {
		if (mon > 1) {
			mon = mon - 1;
		} else {
			year = year - 1;
			mon = 12;
		}
	}
	d.setDate(d.getDate() - n);
	year = d.getFullYear();
	mon = d.getMonth() + 1;
	day = d.getDate();
	// s = year + "-" + (mon < 10 ? ('0' + mon) : mon) + "-" + (day < 10 ? ('0' + day) : day);
	return formatDate(d, t, y);
}
// console.log(getBeforeDate(1)); //昨天的日期
// console.log(getBeforeDate(7)); //前七天的日期

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
	if (typeof Array.isArray === 'undefined') {
		return Object.prototype.toString.call(arg) === '[object Array]'
	}
	return Array.isArray(arg)
}

/*  */
export const dataURLtoBlob = function(dataurl) {
	// var arr = dataurl.split(','),
	// mime = arr[0].match(/:(.*?);/)[1],
	// bstr = atob(arr[1]),
	var bstr = atob(dataurl),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new Blob([u8arr], {
		type: "image/jpg"
	});
}
//2,再将blob转换为file
export const blobToFile = (theBlob, fileName) => {
	theBlob.lastModifiedDate = new Date(); // 文件最后的修改日期
	theBlob.name = fileName; // 文件名
	return new File([theBlob], fileName, {
		type: theBlob.type,
		lastModified: Date.now()
	});
}

// 是否是iOS
export const isSystem = () => {
	const u = navigator.userAgent;
	const isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
	if (isWin) return "window"
	let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
	const ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
		iPad = u.indexOf('iPad') > -1,
		iPhone = u.indexOf('iPhone') > -1 || u.indexOf('Mac') > -1;

	// if (isAndroid) {
	//     return "android"
	// } else if (ios || iPad || iPhone) {
	//     return "ios";
	// } else {
	//     return "window"
	// }
	if (isAndroid) {
		return "android"
	} else {
		return "ios";
	}
	// return ios;
}

/**  * 判断是否在app内 */
export function getMobileOperatingSystem() {
	var userAgent = navigator.userAgent || navigator.vendor || window.opera;

	if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPod/i)) {
		return 'iOS';
	} else if (userAgent.match(/Android/i)) {
		return 'Android';
	} else {
		return 'PC';
	}
}

/**
 * 字符串转对象
 * 字符串格式 {key=value}
 */
export const stringToObj = (str) => {
	let obj = {};
	let _topArr = str.replace(/{|}/g, "").split(",");
	_topArr.map(item => {
		let _o = item.split("="),
			key = _o[0].trim()
		obj[key] = _o[1]
	})
	return obj;
}

/**
 * 防抖
 */
export const Debounce = function(fn, t) {
	let delay = t || 1000;
	let timer = null;
	return function() {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			fn.apply(this, arguments);
		}, delay);
	}
}

//节流
export const Throttle = function(fn, delay = 3000) {
	let canRun = true; // 通过闭包保存一个标记
	return function() {
		if (!canRun) return; //在delay时间内，直接返回，不执行fn
		canRun = false;
		// fn.apply(this, arguments);
		let that = this
		fn.apply(that, arguments);
		setTimeout(() => {
			canRun = true; //直到执行完fn,也就是delay时间后，打开开关，可以执行下一个fn
		}, delay);
	};
}

/**
 * 获取文件的信息
 *
 * @param fileUrl 文件地址
 * @return  文件信息
 */
export function getFileInfo(fileUrl) {
	const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
	const pdflist = ['pdf'];
	const doclist = ['doc', 'docx'];
	const xlslist = ['xls', 'xlsx'];
	const videolist = ['mp4'];
	var type = "";
	let suffix = '';
	let result = '';
	var fileName = getFileName(decodeURI(fileUrl).substring(decodeURI(fileUrl).lastIndexOf('/') + 1));
	const flieArr = fileName.split('.');
	suffix = flieArr[flieArr.length - 1];
	if (suffix != "") {
		suffix = suffix.toLocaleLowerCase();
		//pic
		result = imglist.find(item => item === suffix);
		if (result) {
			return {
				"type": 'image',
				icon: "",
				name: fileName
			};
		}
		//pdf
		result = pdflist.find(item => item === suffix);
		if (result) {
			return {
				"type": "pdf",
				icon: "icon-pdf",
				name: fileName
			};
		}
		//word
		result = doclist.find(item => item === suffix);
		if (result) {
			type = suffix;
			return {
				"type": "word",
				icon: "icon-word",
				name: fileName
			};
		}
		//xls
		result = xlslist.find(item => item === suffix);
		if (result) {
			return {
				"type": "xls",
				icon: "icon-xls",
				name: fileName
			};
		}
		//video
		result = videolist.find(item => item === suffix);
		if (result) {
			return {
				"type": "video",
				icon: "icon-video",
				name: fileName
			};
		}
		return {
			"type": "none",
			icon: "icon-file-unknown-fill",
			name: fileName
		};

	}
}

export const pafPreview = function(pathUrl) {
	// let url = "http://152.136.205.155:8012/onlinePreview?url=" + encodeURIComponent(btoa(pathUrl));
	let url = process.env.VUE_APP_PDF_URl + "/onlinePreview?url=" + encodeURIComponent(btoa(pathUrl));
	// openType：1：默认类型，2，弹出类型
	interaction.bridge({
		msgType: "openNewWindow",
		data: {
			url: url,
			openType: "2",
			canGoBack: true,
			titleVisible: true
		},
	});
}
/**
 * 获取文件的名称
 *
 * @param fileUrl 文件地址
 * @return  文件名称
 */
export function getFileName(fileUrl) {
	var flieArr = fileUrl.split('/');
	return flieArr[flieArr.length - 1];
}

/**
 * 翻译指定value的text
 *
 * @param {Array} arg
 * @param val 翻译前的值
 * @return  翻译后的文本
 */

export function conversion(arg, val, keyValue = {
	key: 'value',
	value: 'text'
}) {
	let item = arg.filter(t => t[keyValue.key] == val)
	if (item && item.length > 0) {
		return item[0][keyValue.value]
	}
}
/* 千分符 */
export const thousandthMark = function(value) {
	if (!value) return 0;
	// 获取整数部分
	const intPart = Math.trunc(value);
	// 整数部分处理，增加,
	const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
	// 预定义小数部分
	let floatPart = '';
	// 将数值截取为小数部分和整数部分
	const valueArray = value.toString().split('.');
	if (valueArray.length === 2) { // 有小数部分
		floatPart = valueArray[1].toString(); // 取得小数部分
		return intPartFormat + '.' + floatPart;
	}
	return intPartFormat + floatPart;
}


//数字转大写
export const arabicNumeralsToCapitalNumerals = function (num) {
  if (!num) return '';
  var AA = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");
  var BB = new Array("", "拾", "佰", "仟", "萬", "億", "圆", "");
  var CC = new Array("角", "分", "厘");
  var a = ("" + num).replace(/(^0*)/g, "").split("."), k = 0, re = "";
  for (var i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0: re = BB[7] + re; break;
      case 4: if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
        re = BB[4] + re; break;
      case 8: re = BB[5] + re; BB[7] = BB[5]; k = 0; break;
    }
    if (k % 4 == 2 && a[0].charAt(i) == "0" && a[0].charAt(i + 2) != "0") re = AA[0] + re;
    if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re; k++;
  }
  if (a.length > 1) {
    re += BB[6];
    for (var i = 0; i < a[1].length; i++) {
      re += AA[a[1].charAt(i)] + CC[i];
      if (i == 2) break;
    }
    if (a[1].charAt(0) == "0" && a[1].charAt(1) == "0") {
      re += "元整";
    }
  } else {
    re += "元整";
  }
  return re;
}

//判断是否为空 true：不为空   false：空
export const notEmpty = function(value) {
	if (value === null || value === undefined || value === "" || value === "null" || value === "undefined") {
		return false;
	}
	return true
}

//去掉千分符
export const removeThousandSeparator = function(value) {
	// 去除输入值中的逗号
	let formattedNumber = value.replace(/,/g, '');
	// 将输入值转为浮点数
	let number = parseFloat(formattedNumber);
	return number;
}


/** 金额保留两位小数 */
export function numberToFixed2(num) {
  return Number(num.toFixed(2));
}

export function getDiffDay(date1, date2) {
  let date1_ = Date.parse(date1), date2_ = Date.parse(date2);
  let diff = (date2_ - date1_) / (1000 * 60 * 60 * 24);
  return diff;
}

// 复制内容
export function copyToClipboard(text) {
	return new Promise((resolve, reject) => {
		const copyElement = document.createElement("textarea");
		copyElement.style.position = "fixed";
		copyElement.style.top = "-9999px";
		copyElement.setAttribute("readonly", "");
		copyElement.value = text;

		document.body.appendChild(copyElement);

		copyElement.select();
		try {
			const success = document.execCommand("copy");
			if (success) {
				resolve("复制成功");
			} else {
				reject("复制失败，请手动复制");
			}
		} catch (error) {
			reject("复制失败，请手动复制");
		} finally {
			document.body.removeChild(copyElement);
		}
	});
}

//判断是否为空
export function isEmpty(value) {
	if (value == "" || value == undefined || value == "undefined" || value == null || value == "null") return true;
	return false;
}

//保留小数，默认保留2位
export function toFixed(num, fixedNum = 2) {
	if (num) {
		return Number(parseFloat(num).toFixed(fixedNum));
	} else {
		return 0
	}
}
/**
 * 导出文件流
 * @param data 文件流本流
 * @param name 文件名称
 * @param suffix 文件后缀，默认.xlsx
 */
export function exportBlob(data, name, suffix = '.xlsx') {
	const link = document.createElement('a'); // 创建一个隐藏的a连接
	link.style.display = 'none';
	// const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset:utf-8' }); // 创建blob对象
	const blob = new Blob([data], {
		type: 'application/vnd.ms-excel'
	}); // 创建blob对象
	const href = URL.createObjectURL(blob); // 设置连接
	link.href = href;
	link.download = Date.now() + name + suffix; // 设置下载名称
	document.body.appendChild(link);
	link.click(); // 模拟点击事件
	document.body.removeChild(link); // 移除元素
	URL.revokeObjectURL(href); // 释放blob对象
}

/* 在线预览 */
export function lookFile(fileUrl) {
	var fileType = getFileInfo(fileUrl).type;
	if (fileType == "image") {
		lookImg(fileUrl);
		return;
	}
	let url = ''
	
	if (fileType == "pdf") {
		url = process.env.VUE_APP_PDF_URl + "/onlinePreview?url=" + encodeURI(Base64.encode(fileUrl));
		interaction.bridge({
			msgType: "openNewWindow",
			data: {
				url: url,
				openType: "2",
				canGoBack: true,
				titleVisible: true
			},
		});
	} else if (fileType == "word") {
		url = process.env.VUE_APP_PDF_URl + "/onlinePreview?url=" + encodeURIComponent(Base64.encode(fileUrl));
		window.open(url);
	} else {
		this.$toast("不支持在线查看", {
			duration: 5000
		});
		return;
	}
}
/* 预览图片 */
export function lookImg(fileUrl) {
	let obj = {
		images: [fileUrl],
		closeable: true,
		maxZoom: 20, // 设置最大放大倍数为20倍
		minZoom: 0.5, // 设置最小缩小倍数为0.5倍
	};
	ImagePreview(obj);
}