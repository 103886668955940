const getters = {
    // sidebar: state => state.app.sidebar,
    // size: state => state.app.size,
    // device: state => state.app.device,
    // visitedViews: state => state.tagsView.visitedViews,
    // cachedViews: state => state.tagsView.cachedViews,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    userInfo: state => state.user.userInfo,
    // introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    systemList: state => state.user.systemList,
    srmUserInfo: state => state.user.srmUserInfo,
    rmsUserInfo: state => state.user.rmsUserInfo,
    upiServerToken: state => state.user.upiServerToken,
    permissions: state => state.user.permissions,
    permission_routes: state => state.permission.addRoutes,
    menu_list: state => state.meun.menuList,
    menuParentId: state => state.meun.menuParentId,
    routerTitle: state => state.meun.meunTitle,
    navbarRightButton: state => state.meun.navbarRightButton,
    rightBtnDefault: state => state.meun.rightBtnDefault,
    include: state => state.include.includelist,
    messageModuleList: state => state.user.messageModuleList,
    platformId: state => state.user.platformId,
}
export default getters